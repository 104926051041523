import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";

import App from "./App";
import "./styles/styles.css";
import { Login } from "./components/login/Login";
import { store } from "./redux/store";
import ErrorPage from "./components/ErrorPage";
import { createRoot } from "react-dom/client";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import GoogleSignIn from "./components/GoogleSignIn";
import ContactUs from "./components/ContactUs";
import BulkPhotoUploader from  "./components/BulkPhotoUploader";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App name="carbonpaper-web" />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "terms-of-service",
    element: <TermsOfService />,
  },
  {
    path: "contact-us",
    element: <ContactUs />,
  },
  // {
  //   path: "home",
  //   element: <GoogleSignIn/>,
  // }
  {
    path: 'bulk-upload',
    element: <BulkPhotoUploader />
  }
]);

const container = document.getElementById("approot");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
